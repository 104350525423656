<template>
  <div class="chat-message" :class="{ 'my-message': isMyMessage }">
    <div class="chat-message__info">
      <div class="chat-message__author">{{ author }}</div>
      <span>●</span>
      <div class="chat-message__date">{{ formatDate(message.sent_at) }}</div>
    </div>
    <div class="chat-message__message">
      {{ message.text }}
    </div>
    <div v-if="fileUrl" class="chat-message__file">
      <LmsLabel variant="file" @click="openFile">{{ message.attachment_name }}</LmsLabel>
    </div>
    <div v-if="status" class="chat-message__status">
      <LmsLabel :variant="labelVariant">{{ status }}</LmsLabel>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LmsLabel from '@/components/TaskChat/Label.vue';

export default {
  name: 'ChatMessage',
  components: { LmsLabel },
  props: {
    chatType: {
      type: String,
      default: 'teacher',
    },
    message: {
      type: Object,
      required: true,
    },
    isLastMessage: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.isLastMessage) {
      this.$emit('lastMessageAppeared');
    }
  },
  computed: {
    ...mapGetters({ user: 'auth/getUser' }),
    ...mapGetters({ chatAuthorName: 'chats/getChatAuthorNameById' }),
    isMyMessage() {
      return this.user?.id === this.message.author_id;
    },
    author() {
      if (this.isMyMessage) {
        return this.$t('label.you');
      }
      if (this.chatType === 'teacher') {
        return this.chatAuthorName(this.message.author_id);
      }
      return 'Менеджер';
    },
    fileUrl() {
      if (this.message?.attachment_url) {
        return this.message.attachment_url;
      }
      return false;
    },
    status() {
      if (this.message?.extension) {
        return this.message.extension[0].Value === 1
          ? this.$t('label.taskIsCompleted')
          : this.$t('label.taskIsRejected');
      }
      return false;
    },
    labelVariant() {
      if (this.message.extension[0].Value === 1) {
        return 'primary';
      }
      if (this.message.extension[0].Value === 2) {
        return 'danger';
      }
      return '';
    },
  },
  methods: {
    formatDate(date) {
      const timeToSet = new Date(date);
      return new Intl.DateTimeFormat(this.$i18n?.locale === 'en' ? 'en' : 'ru', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      }).format(timeToSet);
    },
    openFile() {
      window.open(`https://${this.message.attachment_url}`);
    },
  },
};
</script>
