<!-- eslint-disable max-len -->
<template>
  <div class="extended-clickable" @click.stop="$emit('click')">
    <div>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 4L4 12"
          stroke="#F2F2F2"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M4 4L12 12"
          stroke="#F2F2F2"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CloseBoldSmallIcon',
};
</script>

<style lang="scss" scoped>
.extended-clickable {
  position: relative;
  width: 1rem;
  height: 1rem;

  & > div {
    position: absolute;
    width: 2rem;
    height: 2rem;
    top: -0.5rem;
    left: -0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    svg > path {
      transition: all 150ms ease-in-out;
      stroke: #faa433;
    }
  }
}
</style>
