<!-- eslint-disable max-len -->
<template>
  <div class="lms-label" :class="labelClass" @click="$emit('click')">
    <FileIcon v-if="isFileLabel" />
    <span><slot /></span>
    <svg
      v-if="isFileLabel"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 10V12.6667C14 13.0203 13.8595 13.3594 13.6095 13.6095C13.3594 13.8595 13.0203 14 12.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V10"
        stroke="#F2F2F2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.66602 6.6665L7.99935 9.99984L11.3327 6.6665"
        stroke="#F2F2F2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M8 10V2" stroke="#F2F2F2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  </div>
</template>

<script>
import FileIcon from '@/components/Icons/FileIcon.vue';

export default {
  components: { FileIcon },
  props: {
    variant: {
      type: String,
      default: 'primary',
    },
  },
  name: 'LmsLabel',
  computed: {
    labelClass() {
      if (this.variant === 'primary') {
        return 'lms-label--color-green';
      }
      if (this.variant === 'danger') {
        return 'lms-label--color-danger';
      }

      if (this.isFileLabel) {
        return 'file-label';
      }
      return '';
    },
    isFileLabel() {
      return this.variant === 'file';
    },
  },
};
</script>
