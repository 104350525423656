<template>
  <div class="chat-input">
    <b-form-group
      :id="id"
      :label-for="id"
      :label-class="{
        focus: isFocused || value,
      }"
    >
      <b-input-group>
        <b-input-group-prepend :class="{ focus: isFocused || inputValue.length }">
          <span @click="handleInput">{{ label }}</span>
        </b-input-group-prepend>
        <b-form-input
          :id="id"
          :ref="id"
          v-model="inputValue"
          placeholder=""
          @blur="isFocused = false"
          @focus="isFocused = true"
          @keyup.enter="(v) => $emit('enter', v)"
          autocomplete="off"
        >
        </b-form-input>
        <b-input-group-append>
          <LikeButton
            :showIcon="false"
            v-if="taskStatus"
            :variant="likeButtonVariant"
            @click="$emit('update:taskStatus', null)"
          >
            {{ likeButtonLabel }}
          </LikeButton>
        </b-input-group-append>
      </b-input-group>
    </b-form-group>
  </div>
</template>

<script>
import LikeButton from '@/components/Buttons/LikeButton.vue';

export default {
  name: 'ChatInput',
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    taskStatus: {
      type: String,
      default: null,
    },
  },
  components: {
    LikeButton,
  },
  data: () => ({
    isFocused: false,
  }),
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('update:value', newValue);
      },
    },
    likeButtonVariant() {
      if (this.taskStatus === 'rejected') {
        return 'danger';
      }
      return 'primary';
    },
    likeButtonLabel() {
      if (this.taskStatus === 'rejected') {
        return this.$t('label.taskIsRejected');
      }
      return this.$t('label.taskIsCompleted');
    },
  },
  methods: {
    handleInput() {
      if (!this.isFocused) {
        this.isFocused = true;
        this.$nextTick(() => {
          this.$refs[this.id].focus();
        });
      }
    },
  },
};
</script>
