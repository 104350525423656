<template>
  <div
    class="send-button"
    :class="{ 'send-button--disabled': isDisabledChat }"
    @click="$emit('click')"
  >
    <!-- eslint-disable max-len -->
    <div class="title" v-if="title">
      {{ title }}
    </div>
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_4324_90545)">
        <path
          d="M17.3913 9.196L3.97587 9.19601"
          stroke="#121212"
          stroke-width="1.63398"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17.3912 9.19586L0.926761 17.1232L3.97573 9.19586L0.926761 1.26855L17.3912 9.19586Z"
          stroke="#121212"
          stroke-width="1.63398"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4324_90545">
          <rect width="19" height="19" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'SendArrowButton',
  props: {
    title: {
      type: String,
      default: '',
    },
    isDisabledChat: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
