<template>
  <div class="check-homework-chat">
    <transition name="component-fade" mode="out-in">
      <div v-if="loading" key="loader" class="f-center h-100 mt-auto">
        <div class="scale-05">
          <Loader />
        </div>
      </div>
      <div
        v-else
        key="messages"
        ref="messages"
        class="check-homework-chat__messages"
        :class="{
          'chat-student': chatType === 'student',
          '-mb-15': isTaskComplete || isDisabledChat,
        }"
      >
        <ChatMessage
          v-for="(message, index) in messages.Chat.messages"
          :message="message"
          :key="message.id"
          :isLastMessage="messages.Chat.messages.length - 1 === index"
          @lastMessageAppeared="scrollToBottom"
          :chatType="chatType"
        />
      </div>
    </transition>
    <div v-if="showAttemptLeftInputInfo" class="check-homework-chat__attempt-info">
      <p>{{ $tc('label.attemptsLeft', attemptsLeft) }}</p>
    </div>
    <div v-if="!isTaskComplete && !isDisabledChat" class="check-homework-chat__action chat-action">
      <div class="chat-action__input-block">
        <div class="chat-action__input">
          <ChatInput
            id="chat-input"
            :label="$t('placeholders.enterMessage')"
            :value.sync="messageInput"
            :taskStatus.sync="taskStatus"
            @enter="sendMessage"
            v-if="chatType !== 'student'"
            :class="{
              invalid: $v.messageInput.$dirty && !$v.messageInput.isValid,
            }"
          />
          <LmsInput
            id="chat-input"
            :label="$t('placeholders.enterMessage')"
            :value.sync="messageInput"
            :taskStatus.sync="taskStatus"
            :disabled="isDisabledChat"
            class="student-input-chat"
            :class="{
              invalid: $v.messageInput.$dirty && !$v.messageInput.isValid,
            }"
            @enter="sendMessage"
            v-else
          />
          <div
            class="error-message"
            :class="{
              'error-message--activate': $v.messageInput.$dirty && !$v.messageInput.isValid,
            }"
          >
            <p>{{ $t('errorMessages.pleaseFill') }}</p>
          </div>
        </div>
        <div class="chat-action__buttons-left d-block d-sm-none">
          <AddButton
            :title="buttonFileLabel"
            size="small"
            :variant="[isFileSelected ? 'secondary-file' : 'secondary'][0]"
            :isDisabled="isDisabledChat"
            @click="openFile"
          >
            <template v-if="isFileSelected" #suffixIcon>
              <CloseBoldSmallIcon @click="removeFile" />
            </template>
            <template v-else #suffixIcon>
              <ClipIcon class="ml-2" />
            </template>
          </AddButton>
        </div>
        <div class="chat-action__send-button d-none d-sm-block">
          <SendArrowButton :isDisabledChat="isDisabledChat" @click="sendMessage" />
        </div>
        <div class="chat-action__send-button d-block d-sm-none">
          <SendArrowButton
            :isDisabledChat="isDisabledChat"
            :title="$t('buttonLabels.send')"
            @click="sendMessage"
          />
        </div>
      </div>
      <div class="chat-action__buttons">
        <div class="chat-action__buttons-left d-none d-sm-block">
          <AddButton
            :isDisabled="isDisabledChat"
            :title="buttonFileLabel"
            size="small"
            :variant="[isFileSelected ? 'secondary-file' : 'secondary'][0]"
            @click="openFile"
          >
            <template v-if="isFileSelected" #suffixIcon>
              <CloseBoldSmallIcon @click="removeFile" />
            </template>
            <template v-else #suffixIcon>
              <ClipIcon class="ml-2" />
            </template>
          </AddButton>
        </div>
        <div class="chat-action__buttons-right" v-if="chatType !== 'student'">
          <LikeButton :disabled="isAcceptedLabelDisabled" @click="taskStatus = 'accepted'"
            >{{ $t('buttonLabels.apply') }}
          </LikeButton>
          <LikeButton
            :disabled="isRejectedLabelDisabled"
            variant="danger"
            @click="taskStatus = 'rejected'"
          >
            {{ $t('buttonLabels.reject') }}
          </LikeButton>
        </div>
      </div>
    </div>
    <input
      type="file"
      id="task-loader"
      class="d-none"
      @change="upload"
      ref="load"
      :accept="acceptedTypes"
    />
    <ModalScore :max_score="max_score" @sendScore="sendMessageWithScore" :id="homeworkId"/>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import LikeButton from '@/components/Buttons/LikeButton.vue';
import AddButton from '@/components/Buttons/AddButton.vue';
import CloseBoldSmallIcon from '@/components/Icons/CloseBoldSmallIcon.vue';
import ClipIcon from '@/components/Icons/ClipIcon.vue';
import SendArrowButton from '@/components/Buttons/SendArrowButton.vue';
import ChatMessage from '@/components/TaskChat/ChatMessage.vue';
import ChatInput from '@/components/TaskChat/ChatInput.vue';
import Loader from '@/components/Loader/Loader.vue';
import LmsInput from '@/components/LmsInput.vue';
import StudentService from '@/services/student.service';
import MaterialService from '@/services/material.service';
import ModalScore from '@/components/Modals/ScoreModal.vue';

export default {
  name: 'TaskChat',
  props: {
    chatType: {
      type: String,
      default: '',
    },
    homeworkId: {
      type: String,
      default: '',
    },
    isVisible: {
      type: Boolean,
      required: true,
    },
    activity: {
      Type: Object,
      default: () => ({}),
    },
    max_score: {
      type: Number,
      default: 0,
    },
  },
  components: {
    ChatInput,
    ChatMessage,
    SendArrowButton,
    ClipIcon,
    LikeButton,
    AddButton,
    CloseBoldSmallIcon,
    Loader,
    LmsInput,
    ModalScore,
  },
  data: () => ({
    messages: [],
    taskStatus: null,
    teacherScore: 0,
    messageInput: '',
    selectedMaterial: null,
    fileSizeLimit: 100 * 1024 * 1024, // Mbyte
    formData: null,
    loading: true,
    whiteListExtensions: ['pptx', 'docx', 'pdf', 'zip'],
    isMessagesCached: false,
  }),
  validations: {
    messageInput: {
      isValid(value) {
        if (this.chatType === 'student') {
          return value !== '' || !!this.selectedMaterial?.fileName;
        }
        return value !== '' || !!this.taskStatus || !!this.selectedMaterial?.fileName;
      },
    },
  },
  computed: {
    ...mapGetters({ user: 'auth/getUser' }),
    isFileSelected() {
      return !!this.selectedMaterial?.fileName;
    },
    buttonFileLabel() {
      if (this.isFileSelected) {
        return this.selectedMaterial.fileName;
      }
      return this.$t('buttonLabels.attachFile');
    },
    acceptedTypes() {
      return this.whiteListExtensions.map((ext) => `.${ext}`).join(', ');
    },
    isAcceptedLabelDisabled() {
      return this.taskStatus === 'accepted';
    },
    isRejectedLabelDisabled() {
      return this.taskStatus === 'rejected';
    },
    activityContext() {
      const routeIds = Object.values(this.$route.params);
      routeIds.reverse();
      return routeIds;
    },
    isAttemptsShown() {
      return this.activity.meta?.attempts >= 1;
    },
    usedAttempts() {
      return this.activity.statistic?.meta?.used_attempts || 0;
    },
    showAttemptLeftInputInfo() {
      return (
        !this.isTaskComplete
        && this.chatType === 'student'
        && this.usedAttempts > 0
        && this.isAttemptsShown
        && !this.isDisabledChat
      );
    },
    attemptsLeft() {
      return this.activity.meta?.attempts - this.usedAttempts;
    },
    isDisabledChat() {
      return this.activity.meta?.attempts <= this.usedAttempts && this.isAttemptsShown;
    },
    isTaskComplete() {
      if (this.chatType !== 'student') {
        return false;
      }
      const messages = this.messages?.Chat?.messages || [];
      const messageWithVerdicts = messages.filter((m) => m?.type === 2);
      return messageWithVerdicts[messageWithVerdicts.length - 1]?.extension[0]?.Value === 1;
    },
  },
  methods: {
    ...mapActions({ setToaster: 'toaster/setToaster' }),
    ...mapActions({ approveHomework: 'chats/setHomeworkAsCompleted' }),
    ...mapActions({ rejectHomework: 'chats/setHomeworkAsRejected' }),

    sendMessage() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }

      if (this.chatType === 'student') {
        this.sendMessageByStudent();
      } else {
        if (this.taskStatus && this.max_score !== 0) {
          this.$bvModal.show(`modal-score${this.homeworkId}`);
          return;
        }
        this.sendMessageByTeacher();
      }
      this.$v.$reset();
    },
    sendMessageWithScore(score) {
      this.teacherScore = Math.abs(score);
      this.sendMessageByTeacher();
    },
    async sendMessageByStudent() {
      if (!this.selectedMaterial?.fileName && this.messageInput.length) {
        const newMessage = {
          attachment_url: '',
          author_id: this.user?.id,
          text: this.messageInput,
          sent_at: new Date().toISOString(),
        };
        this.messages.Chat.messages.push(newMessage);
        this.messageInput = '';

        StudentService.sendChatMessageByStudent({
          activity_id: this.activity._id,
          activity_context: this.activityContext,
          text: newMessage.text,
          attachment_url: '',
        }).catch(() => {
          this.messages.Chat.messages.pop();
          this.serverErrorToast();
        });
      } else if (this.selectedMaterial?.fileName) {
        const hasText = !!this.messageInput;
        const newMessage = {
          attachment_name: this.selectedMaterial.fileName,
          author_id: this.user?.id,
          text: this.messageInput,
          sent_at: new Date().toISOString(),
        };
        if (hasText) {
          this.messages.Chat.messages.push(newMessage);
        }

        this.messageInput = '';

        const toastId = Date.now().toString();
        const toastPayload = { id: toastId, meta: { ...this.selectedMaterial } };
        this.makeLoadingToast(toastPayload);
        await this.uploadMaterial(this.formData).then((link) => {
          StudentService.sendChatMessageByStudent({
            activity_id: this.activity._id,
            activity_context: this.activityContext,
            text: newMessage.text,
            attachment_url: link,
            attachment_name: this.selectedMaterial.fileName,
          })
            .then(() => {
              this.$bvToast.hide(toastId);
              this.makeSuccessfullToast(toastPayload);
              if (hasText) {
                this.messages.Chat.messages.splice(this.messages.Chat.messages.length - 1, 1, {
                  ...newMessage,
                  attachment_url: link,
                });
              } else {
                this.messages.Chat.messages.push({ ...newMessage, attachment_url: link });
              }

              this.removeFile();
              this.$nextTick(this.scrollToBottom);
            })

            .catch(() => {
              this.isSending = false;
              this.serverErrorToast();
            });
        });
      }
    },
    async sendMessageByTeacher() {
      if ((this.taskStatus || this.messageInput.length) && !this.selectedMaterial?.fileName) {
        StudentService.sendChatMessageByTeacher({
          task_chat_id: this.homeworkId,
          text: this.messageInput,
          attachment_url: '',
          message_type: !this.taskStatus ? 1 : 2,
          extension: !this.taskStatus
            ? null
            : {
              TaskVerdict: this.taskStatus === 'accepted' ? 1 : 2,
              score: this.teacherScore,
            },
        })
          .then(() => {
            const newMessage = {
              attachment_url: '',
              author_id: this.user?.id,
              text: this.messageInput,
              sent_at: new Date().toISOString(),
              message_type: !this.taskStatus ? 1 : 2,
            };
            if (newMessage.message_type === 2) {
              if (this.taskStatus === 'accepted') {
                this.approveHomework(this.homeworkId);
              } else {
                this.rejectHomework(this.homeworkId);
              }
              newMessage.extension = [
                {
                  Key: 'task_verdict',
                  Value: this.taskStatus === 'accepted' ? 1 : 2,
                },
              ];
            }
            this.messages.Chat.messages.push(newMessage);
            this.messageInput = '';
            this.taskStatus = null;
            this.$v.$reset();
          })
          .catch(() => {
            this.serverErrorToast();
          });
      } else if (this.selectedMaterial?.fileName) {
        const toastId = Date.now().toString();
        const toastPayload = { id: toastId, meta: { ...this.selectedMaterial } };
        this.makeLoadingToast(toastPayload);
        await this.uploadMaterial(this.formData).then((link) => {
          StudentService.sendChatMessageByTeacher({
            task_chat_id: this.homeworkId,
            text: this.messageInput,
            attachment_url: link,
            attachment_name: this.selectedMaterial.fileName,
            message_type: !this.taskStatus ? 1 : 2,
            extension: !this.taskStatus
              ? null
              : {
                TaskVerdict: this.taskStatus === 'accepted' ? 1 : 2,
                score: this.teacherScore,
              },
          })
            .then(() => {
              this.$bvToast.hide(toastId);
              this.makeSuccessfullToast(toastPayload);
              const newMessage = {
                attachment_url: link,
                attachment_name: this.selectedMaterial.fileName,
                author_id: this.user?.id,
                text: this.messageInput,
                sent_at: new Date().toISOString(),
                message_type: !this.taskStatus ? 1 : 2,
              };
              if (newMessage.message_type === 2) {
                if (this.taskStatus === 'accepted') {
                  this.approveHomework(this.homeworkId);
                } else {
                  this.rejectHomework(this.homeworkId);
                }
                newMessage.extension = [
                  {
                    Key: 'task_verdict',
                    Value: this.taskStatus === 'accepted' ? 1 : 2,
                  },
                ];
              }
              this.messages.Chat.messages.push(newMessage);
              this.messageInput = '';
              this.taskStatus = null;
              this.$v.$reset();
              this.removeFile();
            })

            .catch(() => {
              this.isSending = false;
              this.serverErrorToast();
            });
        });
      }
    },
    uploadMaterial(answer) {
      return MaterialService.uploadFile(answer)
        .then(async ({ data: { url } }) => url)
        .catch(() => {});
    },
    scrollToBottom() {
      const msg = this.$refs.messages;
      msg.scrollTop = msg.scrollHeight;
    },
    upload(event) {
      const file = this.$refs.load.files[0];
      if (file) {
        if (!this.validateFileExtension(file)) {
          return;
        }
        if (!this.validateFileSize(file)) {
          return;
        }

        this.formData = new FormData();
        this.formData.append('file', file);

        let files = [];
        files = Array.from(event.target.files);

        files.forEach((f) => {
          const reader = new FileReader();
          const splitNam = f.name.split('.');
          reader.onload = (ev) => {
            const mime = ev.target.result.split(';')[0].split('data:')[1];
            this.formData.append('type', mime);
            this.selectedMaterial = {
              ...this.selectedMaterial,
              fileSrc: ev.target.result,
              fileName: f.name,
              fileExtension: splitNam[splitNam.length - 1],
              fileSize: this.formatBytes(f.size),
            };
          };

          reader.readAsDataURL(f);
        });
      }
    },
    openFile() {
      if (this.isDisabledChat && this.chatType === 'student') {
        return;
      }
      if (this.selectedMaterial?.url && !this.$refs.load?.value) {
        window.open(`https://${this.selectedMaterial.url}`);
      } else {
        this.$refs.load.click();
      }
    },
    removeFile() {
      this.selectedMaterial = null;
      this.formData = null;
      if (this.$refs.load?.value) {
        this.$refs.load.value = null;
      }
    },

    makeLoadingToast(payload) {
      const h = this.$createElement;
      const vNodesTitle = h('div', { class: 'header__wrapper' }, [
        h('div', { class: 'header__first-block' }, [
          h('div', { class: 'header__icon' }, [
            h('img', {
              attrs: {
                src: require('@/assets/images/svg/file-loading-icon.svg'),
              },
            }),
          ]),
          h('div', { class: 'header_tittle' }, this.$t('supportText.uploadingMaterial')),
        ]),
        h('img', {
          on: { click: () => this.$bvToast.hide(payload.id) },
          class: 'header__close-icon',
          attrs: { src: require('@/assets/images/svg/close-icon.svg') },
        }),
      ]);
      const vNodesMsg = h('div', { class: 'body-toast__wrapper' }, [
        h('div', { class: 'body-toast__icon' }, ''),
        h('div', { class: ['body__message'] }, [
          h('div', { class: 'message__title' }, [`${payload.meta.fileName}`]),
          h('div', { class: 'body__message__subtitle' }, [
            `${this.$t('supportText.fileSize')} ${payload.meta.fileSize}`,
          ]),
        ]),
      ]);

      this.$bvToast.toast([vNodesMsg], {
        id: payload.id,
        headerClass: ['header'],
        bodyClass: ['body-toast'],
        title: [vNodesTitle],
        noAutoHide: true,
        toaster: 'b-toaster-bottom-right lms-toast',
        variant: 'info',
        solid: true,
        appendToast: true,
        noCloseButton: true,
      });
    },
    makeSuccessfullToast({ id, meta }) {
      const h = this.$createElement;
      const vNodesTitle = h('div', { class: 'header__wrapper' }, [
        h('div', { class: 'header__icon' }, [
          h('img', {
            attrs: {
              src: require('@/assets/images/svg/successful-icon.svg'),
            },
          }),
        ]),
        h('div', { class: 'header__title' }, this.$t('supportText.materialUploadedSuccessfully')),
        h('img', {
          on: { click: () => this.$bvToast.hide(id) },
          class: 'header__close-icon',
          attrs: { src: require('@/assets/images/svg/close-icon.svg') },
        }),
      ]);
      const vNodesMsg = h('div', { class: 'body-toast__wrapper' }, [
        h('div', { class: 'body-toast__icon' }, ''),
        h('div', { class: ['body-toast__message', 'message'] }, [
          h('div', { class: 'message__title' }, [`${meta.fileName}`]),
          h('div', { class: 'message__subtitle' }, [
            `${this.$t('supportText.fileSize')} ${meta.fileSize}`,
          ]),
        ]),
      ]);

      this.$bvToast.toast([vNodesMsg], {
        id,
        title: [vNodesTitle],
        headerClass: ['header'],
        bodyClass: ['body-toast'],
        autoHideDelay: 30000,
        toaster: 'b-toaster-bottom-right lms-toast',
        variant: 'success',
        solid: true,
        appendToast: true,
        noCloseButton: true,
      });
    },

    validateFileExtension(file) {
      const spiltName = file.name.split('.');
      const flExt = spiltName[spiltName.length - 1];
      if (!this.whiteListExtensions.includes(flExt)) {
        const toast = {
          title: this.$t('errorMessages.fileNotSupported'),
          body: `${this.$t('supportText.onlyOfTheFormat')} ${this.acceptedTypes}`,
        };
        this.setToaster({
          type: 'toast-danger',
          toast,
        });
        return false;
      }
      return true;
    },
    validateFileSize(file) {
      if (this.fileSizeLimit < file?.size) {
        const toast = {
          title: this.$t('errorMessages.sizeToLarge'),
          body: `${this.$t('supportText.maximumFileSize')} - ${this.formatBytes(
            this.fileSizeLimit,
          )}`,
        };
        this.setToaster({
          type: 'toast-danger',
          toast,
        });
        return false;
      }
      return true;
    },

    formatBytes(a, b = 2) {
      if (a === 0) return '0 Bytes';
      const c = b < 0 ? 0 : b;
      const d = Math.floor(Math.log(a) / Math.log(1024));
      return `${parseFloat((a / 1024 ** d).toFixed(c))} ${
        ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][d]
      }`;
    },
    fetchMessages() {
      if (this.chatType === 'student') {
        StudentService.getChatAsStudent({
          activity_id: this.activity._id,
          activity_context: this.activityContext,
        })
          .then((result) => {
            this.messages = result.data;
            this.loading = false;
          })
          .catch(() => {});
      } else {
        StudentService.getChatAsTeacher({
          task_chat_id: this.homeworkId,
        })
          .then((result) => {
            this.messages = result.data;
            this.loading = false;
          })
          .catch(() => {});
      }
    },
    serverErrorToast() {
      const toast = {
        title: this.$t('errorMessages.somethingWentWrong'),
        body: this.$t('errorMessages.tryAgain'),
      };
      this.setToaster({
        type: 'toast-danger',
        toast,
      });
    },
  },
  watch: {
    isVisible: {
      async handler(bool) {
        if (bool && !this.isMessagesCached) {
          await this.fetchMessages();
          this.isMessagesCached = true;
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.check-homework-chat__messages.chat-student ::v-deep .chat-message.my-message {
  margin-left: 0;
}

.check-homework-chat__messages.chat-student ::v-deep .chat-message {
  margin-left: 1.75rem;
}
</style>
