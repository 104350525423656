<template>
  <div
    class="like-btn"
    :class="{ danger: variant === 'danger', disabled: disabled }"
    @click="$emit('click')"
  >
    <!-- eslint-disable max-len -->
    <svg
      v-if="variant !== 'danger' && this.showIcon"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.66732 14.6668H2.66732C2.3137 14.6668 1.97456 14.5264 1.72451 14.2763C1.47446 14.0263 1.33398 13.6871 1.33398 13.3335V8.66683C1.33398 8.31321 1.47446 7.97407 1.72451 7.72402C1.97456 7.47397 2.3137 7.3335 2.66732 7.3335H4.66732M9.33398 6.00016V3.3335C9.33398 2.80306 9.12327 2.29436 8.7482 1.91928C8.37313 1.54421 7.86442 1.3335 7.33398 1.3335L4.66732 7.3335V14.6668H12.1873C12.5089 14.6705 12.8209 14.5578 13.0659 14.3495C13.3109 14.1412 13.4724 13.8514 13.5207 13.5335L14.4407 7.5335C14.4697 7.3424 14.4568 7.14728 14.4029 6.96166C14.349 6.77604 14.2554 6.60436 14.1286 6.45851C14.0017 6.31265 13.8447 6.19612 13.6684 6.11697C13.492 6.03783 13.3006 5.99797 13.1073 6.00016H9.33398Z"
        stroke="#121212"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <svg
      v-else-if="showIcon"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3334 1.33325H13.1134C13.4907 1.32658 13.8573 1.45867 14.1436 1.70446C14.43 1.95025 14.6161 2.29262 14.6667 2.66658V7.33325C14.6161 7.70721 14.43 8.04958 14.1436 8.29537C13.8573 8.54116 13.4907 8.67326 13.1134 8.66658H11.3334M6.66669 9.99992V12.6666C6.66669 13.197 6.8774 13.7057 7.25247 14.0808C7.62755 14.4559 8.13625 14.6666 8.66669 14.6666L11.3334 8.66658V1.33325H3.81335C3.4918 1.32961 3.17977 1.44231 2.93475 1.65058C2.68973 1.85884 2.52824 2.14864 2.48002 2.46658L1.56002 8.46658C1.53102 8.65768 1.5439 8.85279 1.59779 9.03841C1.65168 9.22403 1.74529 9.39572 1.87211 9.54157C1.99894 9.68743 2.15597 9.80396 2.33231 9.8831C2.50864 9.96225 2.70008 10.0021 2.89335 9.99992H6.66669Z"
        stroke="#121212"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <span>
      <slot />
    </span>
  </div>
</template>

<script>
export default {
  name: 'LikeButton',
  props: {
    showIcon: {
      type: Boolean,
      default: true,
    },
    variant: {
      type: String,
      default: 'primary',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
