<template>
  <div class="modal-score">
    <b-modal :id="`modal-score${id}`" hide-footer no-stacking>
      <template #modal-title>
        <div class="modal-score__title">{{ $t('header.taskScore') }}</div>
        <div class="modal-score__subtitle">
          {{ $t('supportText.needToGiveMark') }}
        </div>
      </template>
      <template #modal-header-close>
        <SmallCloseButton />
      </template>
      <template #default>
        <div class="score-block">
          <div class="title">
            <!-- eslint-disable-next-line max-len -->
            {{
              `${$t('supportText.givePoints')} ${passing_score} ${$t(
                'supportText.to',
              )} ${max_score}`
            }}
          </div>
          <div class="input-block">
            <div class="input-container">
              <input
                type="number"
                class="simple-input"
                :class="{
                  invalid: $v.score.$dirty && $v.score.$anyError,
                }"
                v-model="score"
                min="1"
                @keypress="isNumber($event)"
              />
              <div v-if="$v.score.$dirty && $v.score.$anyError" class="helper-text">
                <!-- eslint-disable-next-line max-len -->
                {{
                  `${$t('supportText.max')} ${max_score} ${computePointsForm(max_score)}, ${$t(
                    'supportText.min',
                  )} 1`
                }}
              </div>
            </div>
            <AddButton size="small" :title="$t('buttonLabels.send')" @click="handleSendButton" />
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import SmallCloseButton from '@/components/Buttons/SmallCloseButton.vue';
import AddButton from '@/components/Buttons/AddButton.vue';

export default {
  props: {
    max_score: {
      type: Number,
      required: true,
    },
    passing_score: {
      type: Number,
      default: 1,
    },
    id: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    score: null,
  }),
  validations: {
    score: {
      required,
      maxScoreNotExceeded(int) {
        return int <= this.max_score && int > 0;
      },
    },
  },
  components: {
    SmallCloseButton,
    AddButton,
  },
  methods: {
    handleSendButton() {
      this.$v.score.$touch();
      if (this.$v.score.$anyError) {
        return;
      }
      this.$emit('sendScore', this.score);
      this.$bvModal.hide(`modal-score${this.id}`);
    },
    isNumber(evt) {
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      }
      return true;
    },
    computePointsForm(elem) {
      return this.$tc('label.pointsPlural', elem);
    },
  },
};
</script>
